import Mock from "../mock";

const database = {
  information: {
    name: 'Wesley H. Canosa',
    aboutContent: "Analista de sistemas, desenvolvedor full stack e scrum master. Trabalho com .Net MVC C# há sete anos e também estou me aprofundando em nodejs, reactjs e react native há três anos. Eu trabalho com uma equipe pequena, buscando constantemente evoluir, para que possamos enfrentar os desafios que temos pela frente.",
    age: 34,
    phone: '+55 (14) 99847-8601',
    nationality: 'Brasileiro/Italiano',
    language: 'Português, Inglês',
    email: 'wesley.canosa@gmail.com',
    address: '',
    freelanceStatus: 'Disponível',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/wesley-canosa-820a6817/',
      dribbble: '',
      github: 'https://github.com/asonac'
    },
    brandImage: 'https://avatars2.githubusercontent.com/u/53045953?s=460&u=12a5d56ea22d71b52d2ad6d44d2031404b5ac964&v=4',
    aboutImage: '/images/IMG_7250.jpg',
    aboutImageLg: '/images/IMG_7250.jpg',
    cvfile: '/files/CV.pdf'
  },
  services: [
    {
      title: "Aplicações Back End",
      icon: 'cog',
      details: "Tecnologias: ASP.Net C#, NodeJs \n Banco de dados: SQLServer, Postgres, MongoDB, Redis"
    },
    {
      title: "Aplicações Web",
      icon: 'code',
      details: `Principal Tecnologia: ReactJs Secundária: HTML, CSS, Javascript, JQuery, SCSS`
    },
    {
      title: "Aplicação Móvel",
      icon: 'mobile',
      details: "React Native"
    }
  ],
  reviews: [

    {
      id: 2,
      content: "Tive a chance de trabalhar junto com Wesley como companheiro na programação e líder da equipe. Nesse tempo ele não teve medo do desconhecido nem do novo, sempre abraçou novas tecnologias e métodos para solucionar desafios e dificuldades, procurando agregar valor à sua equipe e realizar um serviço de qualidade.",
      author: {
        name: 'Leonardo Belini',
        designation: 'Desenvolvedor Full Stack, Valore Brasil'
      }
    },
    {
      id: 3,
      content: "O Wesley, é um incrível profissional e nos 2 anos que trabalhamos juntos ele agregou demais com sua experiência e conceitos de metodologia ágil, sendo um notável Scrum Master e sempre incentivando a cada um da equipe a dar o seu melhor para enfrentar os desafios do cotidiano.",
      author: {
        name: 'Celso Reis',
        designation: 'Desenvolvedor Full Stack, Laboratório Sodré.'
      }
    },
    {
      id: 5,
      content: "Sou muito grato por ter trabalhado com ele como líder, sua postura e experiência se torna uma pessoa indispensável em qualquer equipe, tanto na gestão quanto em conhecimento das Stacks, agradeço ao Wesley por ter compartilhado seu conhecimento comigo. Devo muito a ele.",
      author: {
        name: 'Hugo Guedes',
        designation: 'Desenvolvedor Full Stack, LG Lugar de Gente.'
      }
    },
    {
      id: 1,
      content: "Tive o prazer em trabalhar com o Wesley desde quando começou sua carreira profissional, colaboramos juntos em diversos projetos e durante todo esse tempo se mostrou uma pessoa com muita competência, comprometimento e vontade de aprender sempre.",
      author: {
        name: 'Pedro Maitan',
        designation: 'Gestor de TI, Laboratório Sodré'
      }
    },
    {
      id: 4,
      content: "Tive a oportunidade de participar de um time ágil sob a liderança do Wesley. Foi uma ótima experiência, pois ele conseguiu exercer tanto o papel de um líder técnico quanto de um scrum master sempre buscando o máximo engajamento da equipe a fim de proporcionar entregas com qualidade.",
      author: {
        name: 'Naoshi Arimori',
        designation: 'Desenvolvedor Full Stack, Laboratório Sodré.'
      }
    },

  ],
  skills: [
    {
      title: "NodeJs",
      value: 90
    },
    {
      title: "C#.NET",
      value: 90
    },
    {
      title: "ReactJs",
      value: 85
    },
    {
      title: "React Native",
      value: 85
    },
    {
      title: "Javascript",
      value: 90
    },
    {
      title: "JQuery",
      value: 80
    },
    {
      title: "SQL SERVER",
      value: 85
    },
    {
      title: "Postgres",
      value: 75
    },
    {
      title: "Redis",
      value: 85
    },
    {
      title: "MongoDB",
      value: 75
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "GoBarber",
      subtitle: "Aplicação Web & Móvel",
      imageUrl: "/images/gobarber/rsz_login.png",
      largeImageUrl: [
        "/images/gobarber/login.png",
        "/images/gobarber/register.png",
        "/images/gobarber/appointments.png",
        "/images/gobarber/profile.png",
        "/images/gobarber/mobile_splash.png",
        "/images/gobarber/mobile_login.png",
        "/images/gobarber/mobile_appointments.png",
        "/images/gobarber/mobile_barbers.png",
        "/images/gobarber/mobile_profile.png"
    ],
      url: 'https://gobarber.celecsys.com/'
    },
    {
      id: 2,
      title: "Ecoleta",
      subtitle: "Aplicação Web & Móvel",
      imageUrl: "/images/rsz_szv_ecoleta.png",
      largeImageUrl: [
        "/images/ecoleta.png"
      ],
      url: 'https://github.com/asonac/nlw-01'
    },
    {
      id: 3,
      title: "GoRestaurant",
      subtitle: "Aplicação Web & Móvel",
      imageUrl: "/images/gorestaurant/rsz_gorestaurant.png",
      largeImageUrl: [
        "/images/gorestaurant/GoRestaurant.png",
        "/images/gorestaurant/mobile1.png",
        "/images/gorestaurant/mobile2.png",
        "/images/gorestaurant/mobile3.png"
      ],
      url: 'https://github.com/asonac/GoRestaurant-App'
    },
    {
      id: 4,
      title: "GoFinances",
      subtitle: "Aplicação Web",
      imageUrl: "/images/gofinances/rsz_list.png",
      largeImageUrl: ["/images/gofinances/list.png", "/images/gofinances/import.png"],
      url: 'https://github.com/asonac/gostack-modulo3-desafio7-react'
    },
    {
      id: 5,
      title: "GoMarketplace",
      subtitle: "Aplicação Móvel",
      imageUrl: "/images/gomarketplace/rsz_app.png",
      largeImageUrl: ["/images/gomarketplace/list.png","/images/gomarketplace/shopping_cart.png"],

    },
    {
      id: 6,
      title: "Github Explorer",
      subtitle: "Aplicação Web",
      imageUrl: "/images/github_explorer/rsz_seach.png",
      largeImageUrl: ["/images/github_explorer/seach.png", "/images/github_explorer/more_info.png"],
      url: 'https://github.com/asonac/gostack-modulo2-react-git'
    },
    {
      id: 7,
      title: "Be The Hero",
      subtitle: "Aplicação Web & Móvel",
      imageUrl: "/images/bethehero/rsz_bethehero.png",
      largeImageUrl: ["/images/bethehero/bethehero2.png",
      "/images/bethehero/list.png",
      "/images/bethehero/register.png",
      "/images/bethehero/necase.png",
      "/images/bethehero/splash.png",
      "/images/bethehero/mobile-cases.png",
      "/images/bethehero/mobile-bethehero.png"],
      url: 'https://github.com/asonac/BeTheHero'
    },
    {
      id: 8,
      title: "CelecSys",
      subtitle: "Aplicação Web",
      imageUrl: "/images/webcelecsys/rsz_celecsysweb.png",
      largeImageUrl: ["/images/webcelecsys/celecsysweb.png"],
      url: 'http://celecsys.com.br'
    },
    {
      id: 9,
      title: "PecManager",
      subtitle: "Aplicação Web",
      imageUrl: "/images/pecmanager/rsz_pec.png",
      largeImageUrl: ["/images/pecmanager/pec.png",
      "/images/pecmanager/pec2.png",
      "/images/pecmanager/inside.png"
    ],
      url: 'http://pec.celecsys.com.br'
    },
    {
      id: 10,
      title: "Okida Odontologia",
      subtitle: "Aplicação Web",
      imageUrl: "/images/okidaodontologia/rsz_okida.png",
      largeImageUrl: ["/images/okidaodontologia/okida.png",
      "/images/okidaodontologia/okida2.png",
      "/images/okidaodontologia/okida3.png",
      "/images/okidaodontologia/okida4.png"],
      url: 'http://okidaodonto.com'
    },
    {
      id: 11,
      title: "Okida Odontologia Manager",
      subtitle: "Aplicação do Windows",
      imageUrl: "/images/manager/rsz_initial_page.png",
      largeImageUrl: [
        "/images/manager/initial_page.png",
        "/images/manager/manager.png",
        "/images/manager/manager2.png",
        "/images/manager/mana3.png",
      ],
      url: 'https://github.com/asonac/appointment-cashflow-manager.git'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Presente",
        position: "Analista de Sistemas | Desenvolvedor Full Stack | SCRUM Master",
        company: "Laboratório Sodré",
        details: "Modelação, desenvolvimento e manutenção de todo o sistema de produção, desde o gerenciamento de sua cadeia de custódia até a entrega dos resultados aos clientes."
      },
      {
        id: 2,
        year: "2015 - 2017",
        position: "Analista de Sistemas | Desenvolvedor Full Stack",
        company: "Celecsys",
        details: "Modelação, desenvolvimento e manutenção de um sistema ERP para pecuária. Atualmente trabalhando com C# .NET, Visual Studio, SQLServer e muitas outras tecnologias."
      },
      {
        id: 3,
        year: "2014 - 1014",
        position: "Desenvolvedor ABAP",
        company: "Advanced ITeam Soluções e Serviços de TI Ltda",
        details: "Desenvolvedor de aplicações (SAP-ABAP) - ALV, Module Pool."
      },
      {
        id: 4,
        year: "2013 - 2014",
        position: "Desenvolvedor Web",
        company: "CTGEO - CENTRO DE GEOPROCESSAMENTO",
        details: "Comecei o estágio no meu primeiro ano de faculdade. Participei de uma rotação de projetos, aprendendo C#, SQL, entre várias outras tecnologias. Tive a oportunidade de aprender técnicas de Código Limpo e participar de projetos para o desenvolvimento de software de gerenciamento interno na empresa, automatizando e aprimorando o processo de todos os setores."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2020",
        graduation: "RocketSeat GoStack Bootcamp",
        university: "RocketSeat",
        details: "Bootcamp para melhorar meu conhecimento de NodeJs, ReactJs e React Native."
      },
      {
        id: 2,
        year: "2019",
        graduation: "Scrum Master Professional Certificate (SMPC)",
        university: "CertiProf",
        details: "SCRUM é fácil de entender e difícil de dominar."
      },
      {
        id: 3,
        year: "2019",
        graduation: "Scrum Foundation Professional Certificate (SFPC)",
        university: "CertiProf",
        details: "Certificação dos fundamentos do SCRUM."
      },
      {
        id: 4,
        year: "2013 - 2015",
        graduation: "Análise e Desenvolvimento de Sistemas, Tecnologia da Informação",
        university: "Unilins",
        details: "Desenvolver, analisar, projetar, implementar e atualizar sistemas de informação e bancos de dados para diversos setores de atividade. Esses softwares podem executar tarefas rotineiras de uma organização ou fornecer informações estratégicas. Noções de gerenciamento e competência para dar consultoria na área de informática."
      },
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+55 (14) 99847-8601'],
    emailAddress: ['wesley.canosa@gmail.com'],
    address: "Rua José Telles, 180, Lins, São Paulo, Brazil"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});
